* {
    box-sizing: border-box;
}
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}
.show-search-box{
    display:block;
}
.hide-search-box {
    display: none;
}


.card-btm-margin {
    padding: 7px;
    margin-bottom: 7px;
}

.card-button-align {
    text-align: right;
}

.card-shadow {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.card-img-overlay {
    padding: 0.5rem;
}

.navbar-brand {
    font-size: 2.0rem;
}

.card-body {
    padding: 0.75rem;
}

.imgSize {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.loginScreen {
    border: 1px solid #d3d3d3;
    border-radius: .5em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
    padding: 1em;
    text-align: left;
    width: 600px;
}

.loginForm {
    padding: 1em;
}

.demo-image {
    display: inline-block;
    width: 100%;
    height: 175px;
    background-size: cover;
    background-position: 50% 50%;
}

.demo-image-asset {
    display: inline-block;
    width: 100%;
    height: 85px;
    background-size: cover;
    background-position: 50% 50%;
}

.gallery-tags-bottom-left {
    background: rgba(0,0,0,.4);
    bottom: 50px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
}

.uil-spin-css > div {
    position: absolute;
}

    .uil-spin-css > div > div {
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }

    .uil-spin-css > div:nth-of-type(1) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0s;
        -moz-animation-delay: 0s;
        -webkit-animation-delay: 0s;
        -o-animation-delay: 0s;
        animation-delay: 0s;
    }

    .uil-spin-css > div:nth-of-type(1) {
        -ms-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
    }

    .uil-spin-css > div:nth-of-type(2) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0.12s;
        -moz-animation-delay: 0.12s;
        -webkit-animation-delay: 0.12s;
        -o-animation-delay: 0.12s;
        animation-delay: 0.12s;
    }

    .uil-spin-css > div:nth-of-type(2) {
        -ms-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
    }

    .uil-spin-css > div:nth-of-type(3) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0.25s;
        -moz-animation-delay: 0.25s;
        -webkit-animation-delay: 0.25s;
        -o-animation-delay: 0.25s;
        animation-delay: 0.25s;
    }

    .uil-spin-css > div:nth-of-type(3) {
        -ms-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
    }

    .uil-spin-css > div:nth-of-type(4) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0.37s;
        -moz-animation-delay: 0.37s;
        -webkit-animation-delay: 0.37s;
        -o-animation-delay: 0.37s;
        animation-delay: 0.37s;
    }

    .uil-spin-css > div:nth-of-type(4) {
        -ms-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
    }

    .uil-spin-css > div:nth-of-type(5) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0.5s;
        -moz-animation-delay: 0.5s;
        -webkit-animation-delay: 0.5s;
        -o-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }

    .uil-spin-css > div:nth-of-type(5) {
        -ms-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
    }

    .uil-spin-css > div:nth-of-type(6) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0.62s;
        -moz-animation-delay: 0.62s;
        -webkit-animation-delay: 0.62s;
        -o-animation-delay: 0.62s;
        animation-delay: 0.62s;
    }

    .uil-spin-css > div:nth-of-type(6) {
        -ms-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
    }

    .uil-spin-css > div:nth-of-type(7) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0.75s;
        -moz-animation-delay: 0.75s;
        -webkit-animation-delay: 0.75s;
        -o-animation-delay: 0.75s;
        animation-delay: 0.75s;
    }

    .uil-spin-css > div:nth-of-type(7) {
        -ms-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
    }

    .uil-spin-css > div:nth-of-type(8) > div {
        -ms-animation: uil-spin-css 1s linear infinite;
        -moz-animation: uil-spin-css 1s linear infinite;
        -webkit-animation: uil-spin-css 1s linear infinite;
        -o-animation: uil-spin-css 1s linear infinite;
        animation: uil-spin-css 1s linear infinite;
        -ms-animation-delay: 0.87s;
        -moz-animation-delay: 0.87s;
        -webkit-animation-delay: 0.87s;
        -o-animation-delay: 0.87s;
        animation-delay: 0.87s;
    }

    .uil-spin-css > div:nth-of-type(8) {
        -ms-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
        -moz-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
        -webkit-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
        -o-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
        transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
    }

.autocomplete-wrapper {
    width: 350px;
    position: relative;
    display: inline-block;
}

    .autocomplete-wrapper > div {
        width: 100%;
    }

    .autocomplete-wrapper input {
        border: 1px solid #cecece;
        padding: 12px 15px;
        font-size: 14px;
        width: 100%;
    }

        .autocomplete-wrapper input:focus {
            border-color: #0F67FF;
            box-shadow: none;
            outline: none;
        }

    .autocomplete-wrapper .dropdown {
        width: 100%;
        padding: 0;
        text-align: left;
        max-height: 280px;
        overflow: hidden;
        overflow-y: auto;
        background-color: #ffffff;
        border: 1px solid #0F67FF;
        border-top: none;
    }

    .autocomplete-wrapper .item {
        display: block;
        cursor: pointer;
        font-size: 14px;
        padding: 15px;
    }

        .autocomplete-wrapper .item.selected-item {
            background-color: #0069ff;
            color: #FAFBFC;
        }

        .autocomplete-wrapper .item:hover {
            background-color: #0069ff;
            color: #FAFBFC;
        }

.show-gallery {
    display: block;
}

.hide-gallery {
    display: none;
}

.show-grid-view {
    display: block;
}

.hide-grid-view {
    display: none;
}
@keyframes slidein {
    0% {
        top: -1900px;
    }

    100% {
        top: 0px;
    }
}
 	
